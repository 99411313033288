import { Dispatch } from 'react';
import { handleError } from 'contexts/utils';
import { TCallActionTypes } from '../types';
import { GENERATE_TOKEN } from '../constants';
import api from 'api/api';
import { AxiosError } from 'axios';

/**
 * Will hang up the call
 *
 * @param {Dispatch<TCallActionTypes>} dispatch Function that update state
 */
export const generateToken = async (teamId: string, dispatch: Dispatch<TCallActionTypes>) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    const response = await api.get(`/calls/${teamId}/token`, config);
    dispatch({ type: GENERATE_TOKEN, payload: response.data.token });
    return response.data.token;
  } catch (error) {
    dispatch(handleError(error as AxiosError));
  }
};
