import { AxiosError } from 'axios';
import { ISetError } from './types';
import { SET_ERROR } from 'contexts/types';

type TRequestError = {
  response: {
    data: {
      error: string;
    };
  };
};

/**
 * Will handle state's errors in order to be from axios or error
 *
 * @param {*} error
 * @return {*}  {ISetError}
 */
export const handleError = (error: AxiosError | Error): ISetError => {
  if ('response' in error) {
    const errorMessage = (error as TRequestError).response?.data.error;
    return { type: SET_ERROR, payload: errorMessage };
  } else if (error instanceof Error) {
    return { type: SET_ERROR, payload: error.message };
  } else {
    return { type: SET_ERROR, payload: 'unknown error' };
  }
};
