import { ITableQuery } from 'components/Shared/EnchancedTable/types';
import { IRecordingInitialState } from '../types';

export const DEFAULT_RECORDING_STATE: IRecordingInitialState = {
  recordings: null,
  loading: false,
  success: null,
  error: null,
  count: 0,
  getRecordings: (params: ITableQuery) => {},
  setLoading: () => {},
  clearSnackbars: () => {},
  clearState: () => {}
};
