import { IRecordingInitialState } from '../types';

/**
 * Will update recording state
 *
 * @param {IRecordingInitialState} state
 * @param {*} payload
 */
export const updateState = (state: IRecordingInitialState, payload: Partial<IRecordingInitialState>) => ({
  ...state,
  loading: false,
  error: null,
  ...payload
});
