import { Dispatch } from 'react';
import { GET_RECORDINGS } from '../constants';
import { AxiosError } from 'axios';
import { handleError } from 'contexts/utils';
import { SET_LOADING } from 'contexts/types';
import { IRecordingReducer } from '../types';
import api from 'api/api';
import { ITableQuery } from 'components/Shared/EnchancedTable/types';

/**
 * Will get the recordings from the database
 *
 * @param {Dispatch<IRecordingReducer> } dispatch Function that update state
 */
export const getRecordings = async (dispatch: Dispatch<IRecordingReducer>, params: ITableQuery) => {
  dispatch({ type: SET_LOADING });

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  };

  try {
    const response = await api.post('/recordings/searchRecordingDocuments', params, config);

    dispatch({
      type: GET_RECORDINGS,
      payload: {
        recordings: response.data.recordings.data,
        count: response.data.recordings.count,
        success: 'Recordings.List.Success'
      }
    });
  } catch (error) {
    dispatch(handleError(error as AxiosError));
  }
};
