import { CLEAR_SNACKBAR, CLEAR_STATE, SET_ERROR, SET_LOADING } from 'contexts/types';
import { DEFAULT_RECORDING_STATE, GET_RECORDINGS } from './constants';
import { IRecordingInitialState, IRecordingReducer } from './types';
import { updateState } from './utils';

export const RecordingReducer = (state: IRecordingInitialState, action: IRecordingReducer): IRecordingInitialState => {
  const { type, payload = {} } = action;

  switch (type) {
    case GET_RECORDINGS:
      return updateState(state, payload as Partial<IRecordingInitialState>);
    case CLEAR_SNACKBAR:
      return updateState(state, { error: null, success: null });
    case CLEAR_STATE:
      return updateState(state, DEFAULT_RECORDING_STATE);
    case SET_ERROR:
      return updateState(state, payload as Partial<IRecordingInitialState>);
    case SET_LOADING:
      return updateState(state, { loading: true });
    default:
      return state;
  }
};
