import React, { useReducer, createContext } from 'react';
import { DEFAULT_RECORDING_STATE } from './constants';
import { RecordingReducer } from './RecordingReducer';
import { getRecordings } from './http';
import { CLEAR_SNACKBAR, CLEAR_STATE, SET_LOADING } from '../types';
import { ITableQuery } from 'components/Shared/EnchancedTable/types';
export const RecordingContext = createContext(DEFAULT_RECORDING_STATE);

export const RecordingState = (props: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(RecordingReducer, DEFAULT_RECORDING_STATE);

  return (
    <RecordingContext.Provider
      value={{
        ...state,
        clearState: () => dispatch({ type: CLEAR_STATE }),
        setLoading: () => dispatch({ type: SET_LOADING }),
        clearSnackbars: () => dispatch({ type: CLEAR_SNACKBAR }),
        getRecordings: (params: ITableQuery) => getRecordings(dispatch, params)
      }}
    >
      {props.children}
    </RecordingContext.Provider>
  );
};
